import axios from 'axios';
import router from '@/router';
import Swal from 'sweetalert2'
const physician = {
    state: {
        baseUrl: process.env.VUE_APP_MOREPLEX_API ? process.env.VUE_APP_MOREPLEX_API : "",
        token: JSON.parse(sessionStorage.getItem("token")),
        profile_code: JSON.parse(sessionStorage.getItem("profile_code")),
        all_physician: [],
        physician: "",
        all_users: []

    },

    mutations: {
        ADD_NEW_PHYSICIAN(state, params) {
            axios
                .post(state.baseUrl + "/api/user_auth/physician/store", params, {
                    headers: { Authorization: `Bearer ${state.token}` },
                }).then((res) => {
                    if (res.data.status == 'success') {
                        Swal.fire({
                            title: "Success!",
                            text: res.data.message,
                            icon: "success"

                        });
                        router.push('/physician')
                    } else {

                        Swal.fire({
                            title: "Error!",
                            text: res.data.message,
                            icon: "error",
                        })
                    }
                    // console.log(res.data)
                }).catch((err) => {
                    console.log(err)
                })

        },

        ALL_PHYSICIAN(state) {
            axios.get(state.baseUrl + "/api/physician_auth/physician", {
                headers: { Authorization: `Bearer ${state.token}` },
            }).then((res) => {
                state.all_physician = res.data.all_physician
                console.log(state.all_physician)
            }).catch((err) => {
                console.log(err)
            })
        },

        EDIT_PHY(state, data) {
            let params = {
                firstname: data.firstname,
                lastname: data.lastname,
                middlename: data.middlename,
                email: data.email,
                phone_no: data.phone_no,
                address: data.address
            };
            axios
                .patch(state.baseUrl + "/api/physician_auth/physician/update/" + data.phy_id, params, {
                    headers: { Authorization: `Bearer ${state.token}` },
                }).then((res) => {
                    if (res.data.status == 'success') {
                        Swal.fire({
                            title: "Good job!",
                            text: res.data.message,
                            icon: "success",

                        });
                        window.location.reload();
                        // this.$router.go('/')
                    } else {
                        Swal.fire({
                            title: "Good job!",
                            text: res.data.message,
                            icon: "error",
                        });
                    }
                    console.log(res.data)
                }).catch((err) => {
                    console.log(err)
                })

        },



        PHY_DET(state,) {
            const profile_code = JSON.parse(sessionStorage.getItem("profile_code"));
            axios.get(state.baseUrl + "/api/physician_auth/physician/show/" + profile_code, {
                headers: { Authorization: `Bearer ${state.token}` },
            }).then((res) => {
                state.physician = res.data.patient_details;
                console.log(res.data.patient_details)
            }).catch((err) => {
                console.log(err)
            })
        },


        ADD_NEW_USER(state, params) {
            axios
                .post(state.baseUrl + "/api/user_auth/register", params, {
                    headers: { Authorization: `Bearer ${state.token}` },
                }).then((res) => {
                    if (res.data.status == 'success') {
                        //  alert("success"+res.data.message)
                        Swal.fire({
                            title: "Good job!",
                            text: res.data.message,
                            icon: "success",

                        });
                     //   location.reload();

                      //  console.log(res.data)
                      router.push('/')
                     
                    } else {
                        //  alert("error"+res.data.message)
                        //  location.reload()
                        Swal.fire({
                            title: "Error!",
                            text: res.data.message,
                            icon: "error",
                        })
                    }
                    console.log(res.data)
                }).catch((err) => {
                    console.log(err)
                })

        },

        ALL_USERS(state) {
            axios.get(state.baseUrl + "/api/user_auth/users", {
                headers: { Authorization: `Bearer ${state.token}` },
            }).then((res) => {
                state.all_users = res.data.users
                console.log(res)
            }).catch((err) => {
                console.log(err)
            })
        },

        EDIT_USER(state, data) {
            let params = {
                firstname: data.firstname,
                lastname: data.lastname,
                middlename: data.middlename,
                email: data.email,
                phone_no: data.phone_no,
                user_type: data.user_type
            };
            axios
                .patch(state.baseUrl + "/api/user_auth/user/update/" + data.phy_id, params, {
                    headers: { Authorization: `Bearer ${state.token}` },
                }).then((res) => {
                    if (res.data.status == 'success') {
                        Swal.fire({
                            title: "Good job!",
                            text: res.data.message,
                            icon: "success",

                        });
                        window.location.reload();
                        // this.$router.go('/')
                    } else {
                        Swal.fire({
                            title: "Good job!",
                            text: res.data.message,
                            icon: "error",
                        });
                    }
                    console.log(res.data)
                }).catch((err) => {
                    console.log(err)
                })

        },



        DELETE_PHY(state, id) {
            axios
                .delete(state.baseUrl + "/api/user_auth/physician/delete/" + id, {
                    headers: { Authorization: `Bearer ${state.token}` },
                }).then((res) => {
                    location.reload()
                }).catch((err) => {
                    console.log(err)
                })

        },

        DELETE_USER(state, id) {
            axios
                .delete(state.baseUrl + "/api/user_auth/user/delete/" + id, {
                    headers: { Authorization: `Bearer ${state.token}` },
                }).then((res) => {
                    location.reload()
                }).catch((err) => {
                    console.log(err)
                })

        },





    },
    actions: {
        async add_new_physician({ commit }, params) {
            await commit('ADD_NEW_PHYSICIAN', params)
        },
        async all_physician({ commit }) {
            await commit('ALL_PHYSICIAN')
        },

        async edit_physician({ commit }, params) {
            await commit('EDIT_PHY', params)
        },
        async phy_det({ commit }, profile_code) {
            await commit('PHY_DET', profile_code)
        },

        async add_new_user({ commit }, params) {
            await commit('ADD_NEW_USER', params)
        },
        async all_users({ commit }) {
            await commit('ALL_USERS')
        },
        async edit_user({ commit }, params) {
            await commit('EDIT_USER', params)
        },
        async delete_phy({ commit }, id) {
            await commit('DELETE_PHY', id)
        },
        async delete_user({ commit }, id) {
            await commit('DELETE_USER', id)
        },






    }
}

export default physician
