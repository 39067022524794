<template>
  <div class="main">
    <div class="container-fluid">
      <div class="home">
        <div class="form_container ">
          <div class="row ">
            <div class="col-sm-6  logo">
              <h5>IsoCare</h5>
            </div>
            <div class="col-sm-6   log">
              <form>
                <div class="form-group mt-3">
                  <input type="text" placeholder="Email" v-model="email" />
                </div>

                <div class="form-group mt-4">
                  <input type="password" placeholder="Password" v-model="password" />
                </div>
                <div class="form-group mt-4">
                  <button @click.prevent="logIN()" class="btn btn-success">
                    <i :class="loader"></i> {{ msg }}
                  </button>
                  <p class="text-center mt-3" style="font-size: 20px; font-weight: bold;">Forgot password? <router-link
                      to="/forgot">Reset</router-link> </p>
                </div>
              </form>
            </div>

          </div>
        </div>

      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
  data() {
    return {
      msg: "Log In",
      email: "",
      password: "",
      loader: ""
    };
  },
  methods: {
    logIN() {
      if (this.email == "") {
        Swal.fire({
          title: "ERROR!",
          text: "Email can not be empty, input your mail",
          icon: "error",
        });
        return false;
      }
      if (this.password == "") {
        Swal.fire({
          title: "ERROR!",
          text: "Password can not be empty, input your mail",
          icon: "error",
        });
        return false;
      }
      this.msg = "Loading...";
      this.loader = "fa fa-spinner fa-spin";
      var params = new FormData();
      params.append("email", this.email);
      params.append("password", this.password);
      this.$store.commit("LOG_IN", params);
    },
  },
};
</script>

<style scoped>
.main {
  background: url(../assets/log_image_.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
  color: black;
}

.form_container {
  width: 80%;
  margin: 50px auto;
  background: rgba(204, 211, 213, 0.8);
  padding: 10px;

  border-radius: 20px;

}

.log {
  margin-top: 1rem;
}

.logo {
  border-right: 2px solid black;
  margin-top: 1rem;
}

.logo h5 {
  font-size: 100px;
  margin: 40px 100px;
}

.form-group {
  width: 80%;
  margin: auto;
}

.form-group input {
  border: 2px solid black;
  color: white;
  background: rgb(70, 67, 67);
  outline: none;
  width: 100%;
  padding: 10px 10px;
  margin: auto;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: none;
}

/* .form-group input:focus {
  border: 2px solid green;
} */

.btn {
  width: 100%;
}

.home {
  height: 300px;
  padding-top: 130px;
}



@media only screen and (max-width: 600px) {


  .form_container {
  width: 100%;
  
  margin: 20px auto;
 
}

.logo h5 {
  font-size: 50px;
  text-align: center;
  margin: 40px 10px;
}
.logo {
  border-right: 0px solid black;
  margin-top: 1rem;
}
.log {
  margin-top: 0.2rem;
}

}
.home {
  height: 300px;
  padding-top: 80px;
}






</style>
