<template>
  <router-view />
</template>

<script >

import { ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
//import Swal from 'sweetalert2';

export default {
  setup() {
    const idleTime = ref(0);
    const maxIdleTime = 60; // in minutes
    let timeoutId;
    const store = useStore();

    const resetIdleTime = () => {
      idleTime.value = 0;
    };


    const startIdleTimer = () => {
      if (sessionStorage.getItem("token") == "" || sessionStorage.getItem("token") != "undefine") {
        clearTimeout(timeoutId);
      } else {
        timeoutId = setTimeout(() => {
          alert('User has been idle for too long. Logging out...');
          store.dispatch('logout');
          // Perform logout action here
        }, maxIdleTime * 60 * 1000);
      }

    };

    const resetIdleTimer = () => {
      clearTimeout(timeoutId);
      startIdleTimer();
    };


    onMounted(() => {
      // alert("welcome")
      window.addEventListener('mousemove', resetIdleTimer);
      window.addEventListener('keypress', resetIdleTimer);
      startIdleTimer();
    });

    onUnmounted(() => {
      window.removeEventListener('mousemove', resetIdleTimer);
      window.removeEventListener('keypress', resetIdleTimer);
      clearTimeout(timeoutId);
    });

    return {
      idleTime,
    };
  },
};



</script>


<style>
body {
  padding: 0px !important;
  margin: 0px !important;
  font-family: "Inter" !important;
  scrollbar-width: none !important;
  background: #032d7c !important;
  box-sizing: border-box !important;
  -ms-overflow-style: none !important;
  width: 100%;
  /* for Internet Explorer, Edge */
}

html {
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  text-decoration: none !important;
}
</style>
